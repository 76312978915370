@import './src/scss/variables';

.dark-theme {
	.grid__cell::after {
		border-color: $dark-color2;
	}

	.grid.retry .grid__cell.circle::after {
    background-color: $dark-color;
	}

	.grid.ready .grid__cell.circle.visible::after {
    background-color: $dark-color;
	}

	.grid.game .grid__cell.valid::after {
    background-color: $dark-color;
	}

	.grid.game .grid__cell.invalid::after, .grid.game .grid__cell.valid::after {
    background-color: $dark-color;
	}
}