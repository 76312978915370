@import './src/scss/variables';
@import './src/scss/game';

$grid_cell_size: calc(131px - (var(--grid-size) * 8px));

:root {
  --grid-size: 4;
}

$max_grid_size: 8;

.grid {
  position: relative;

  &__row {
    display: flex;
    margin: 0;

    &:last-child {
      .grid__cell {
        border-bottom: none;
      }
    }

    .grid__cell {
      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  &__cell {
    width: $grid_cell_size;
    aspect-ratio: 1 / 1;
    border-right: 1px solid;
    border-bottom: 1px solid;
    box-sizing: content-box !important;
    position: relative;

    &::after {
      position: absolute;
      border: .5rem solid $color2;
      border-radius: 50%;
      box-sizing: border-box;
      width: 100%;
      aspect-ratio: 1 / 1;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.game__content {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  cursor: default;
  user-select: none;
}

.game__container {
  position: relative;
  flex-direction: column;
  align-items: center;
}

.grid.ready {
  .grid__cell {
    &.circle.visible {
      &::after {
        content: "";
        background-color: $color;
      }
    }
  }
}

.grid.game {
  .grid__cell {
    cursor: pointer;

    &.invalid, &.valid {
      &::after {
        content: "";
        background-color: $color;
      }
    }
  }
}

.grid.end, .grid.retry {
  .grid__cell {
    &.circle {
      &::after {
        content: "";
        background-color: $color;
      }
    }

    &.invalid {
      pointer-events: none;

      &::after {
        content: "";
        background-color: $color11;
      }
    }

    &.valid.circle {
      pointer-events: none;

      &::after {
        content: "";
        background-color: $color10 !important;
      }
    }
  }
}

.game__button {
  justify-content: center;
  margin: 1rem 0 0;

  button {
    font-weight: 600;
    background: transparent;
    font-family: "Open Sans", sans-serif;
    font-size: $font-size;
    color: $color;

    &:hover {
      opacity: $opacity;
    }
  }
}

@import 'dark-theme';